import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Heading, Content } from 'react-bulma-components';


const IndexPage = () => (  
  <Layout>
    <SEO title="Tłumaczenia Przysięgłe na francuski" keywords={[`francuski`,`tłumacz przysięgły`,`tłumaczenia`]} />
    <Heading>Tłumacz przysięgły języka francuskiego</Heading>
    <Content>
    <p>
    Tłumaczenia o tematyce prawniczej, handlowej i technicznej, w zakresie ochrony środowiska. 
    Trzydziestoletnie doświadczenie w tłumaczeniach na francuski dokumentów prawniczych dotyczących spółek, 
    umów handlowych i umów prawa cywilnego. Licencje, prawo autorskie. Dokumentacja US ZUS USC. 
    </p>
    <p>
    Tłumaczenia dokumentów samochodowych <strong>Członek Zwyczajny TEPIS</strong> Praktyczne doświadczenie w prowadzeniu spółek francuskich, 
    członek rad nadzorczych. Doświadczenie w bankowości i tematyce informatycznej. 
    </p>
    <p>
    Kilkadziesiąt tysięcy stron tłumaczeń dotyczących kompletnych projektów inwestycyjnych z zakresu ochrony środowiska, 
    w tym tłumaczenia ustne. Kilka pełnych unijnych dyrektyw, zielona księga i inne.
    </p>
    
    <h3>Kontakt</h3>
    <address>
    Marsylska 5/27 
    02-763 Warszawa
    </address>
    Tel.: <a href="tel:+48505011202">505 011 202</a> | E-mail: <a href="mailto:biuro@tlumaczenia-francuski.pl">biuro@tlumaczenia-francuski.pl</a> | 
    NIP: <a href="https://prod.ceidg.gov.pl/CEIDG/CEIDG.Public.UI/SearchDetails.aspx?Id=c17576c2-884d-4780-b768-33a33cef3d22">5211735557</a><br/>
    Telefon stacjonarny <a href="tel:228428522">22 84 285 22</a><br/>
    Wyślij e-mail, faks lub dostarcz osobiście, aby otrzymać wycenę.

    <h3>Cennik tłumaczeń</h3>
    <p><strong>Tryb zwykły</strong> - do 5 stron tłumaczenia dziennie<br/>
    Z języka francuskiego:	30-35 zł / strona obliczeniowa	1500 znaków tekstu tłumaczenia<br/>
    Na język francuski:	38 zł	 <br/>
    <strong>Tłumaczenia specjalistyczne</strong><br/>
    Z języka francuskiego:	35-42 zł / strona obliczeniowa	1600 znaków tekstu tłumaczenia<br/>
    Na język francuski:	38-45 zł	 
    </p>
    <p>
    <ul>
      <li>Rabat 10% w przypadku płatności Bitcoin za tłumaczenie</li>
      <li>W trybie pilnym dolicza się 100% do stawki podstawowej dla tłumaczeń przysięgłych, 50% w przypadku tłumaczeń zwykłych.</li>
    <li>Za uwierzytelnienie tłumaczenia z języka francuskiego : 12,50 zł  za 1125 znaków</li>
      <li>
    Za uwierzytelnienie tłumaczenia na język francuski : 15,00 PLN za 1125 znaków</li>
      <li>
    Dodatkowe egzemplarze uwierzytelnione – 10% ceny tłumaczenia, każdy.</li>
    </ul>
    </p>
    </Content>
  </Layout>
)

export default IndexPage
